import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PostsLayout from 'components/posts-layout';
import PostFooter from 'components/post-footer';
import Image from 'components/image';
import SEO from 'components/seo';
import Links from 'components/links';
import { LinkLabel } from 'utils/constants';
import { postMap, slugify } from 'utils/post-map';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PostsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`My life vision`}</h1>
    <p>{`This will be the most important email I send you.`}</p>
    <p>{`And yet - 70% of you will read it and take no action.`}</p>
    <p>{`That’s not a knock on you. It’s human nature.`}</p>
    <p>{`We know we shouldn’t eat french fries & big macs…yet McDonalds stock is up 3x this decade.`}</p>
    <p>{`So this email really is for the 30% of you that will say “I love this. I’m gonna do it.”`}</p>
    <p>{`Quick sidenote - isn’t that funny how we can split the population into people who say `}<strong parentName="p">{`“I should do that”`}</strong>{` vs. `}<strong parentName="p">{`“I will do that”`}</strong></p>
    <p><strong parentName="p">{`“Should”`}</strong>{` is a dangerous word. People “should” all over themselves. I try to steer clear of people who say the “S word” too often. They are people who never really achieve their dreams.`}</p>
    <p>{`I’ll let my daughter Blush say f*ck…but if she starts saying “should”, she’s in trouble!`}</p>
    <p>{`Anyways. Back to `}<strong parentName="p"><em parentName="strong">{`the most important email`}</em></strong>{`.`}</p>
    <p>{`This email is about having a vision for your life.`}</p>
    <p><em parentName="p">{`"record screeeeeeech"`}</em></p>
    <p>{`Yea, that sounds like some hippy dippy shit.`}</p>
    <p>{`But having a vision (or a dream) for your life is important. It gives you a north star of where you’re trying to go, which makes your day-to-day decisions much easier.`}</p>
    <p>{`I talk to a lot of people who are frustrated or feeling down on life. And so I ask them - so what would it look like if everything went right? What’s the dream? What is winning at life for you?`}</p>
    <p>{`Most people struggle to answer.`}</p>
    <p>{`In fact…I think that is often the cause of the frustration. If you don’t have a vision for your life..then how do you feel a sense of progress? Hell, I’d be frustrated too if I was shooting at an invisible target.`}</p>
    <p>{`I’m not a bible guy, but my guy Jesus said it best: Without a vision ye shall perish.`}</p>
    <p>{`It sounds so straight forward. It can’t hurt right? So why do so few people have one?`}</p>
    <p>{`I think I figured it out.`}</p>
    <p>{`Having a “life vision” not only sounds cheesy. It sounds scary. That’s a lot of pressure, coming up with a good life vision. What if I leave something important out? What if it’s too simple, am I just settling? What if it’s too ambitious and I don’t achieve it?`}</p>
    <p>{`Deep breath. Relax. Don’t worry. Ya boi shaan is here to help.`}</p>
    <p>{`So if you’re with me….if you believe that having a vision for your life could be helpful…If you think that knowing what you want would help you get what you want…then read on.`}</p>
    <p>{`If you don’t - just unsubscribe to this list! This is a tribe of people who like this kinda shit!`}</p>
    <p><strong parentName="p">{`Examples of life visions:`}</strong></p>
    <p>{`Before writing this post, I did a bit of research.`}</p>
    <p>{`I emailed 12 of my closest friends and asked them - “what is your vision for your life, in a tweet length”?`}</p>
    <p>{`I’ll respect their privacy and not share their names, but here are their responses.`}</p>
    <p>{`There is of course, no right answer. But there are interesting answers.`}</p>
    <p>{`I’m going to share some here in case one resonates with you:`}</p>
    <p><strong parentName="p">{`Person #1 - Male, 32`}</strong></p>
    <p>{`I become the brown version of tony robbins (minus the gigantism). I stay curious. Learn things. Distill the learnings down, & teach it to others.`}</p>
    <p>{`I have a house full of love, in a neighborhood full of friends like yall! When I die, my ideas live on in the minds of my students!`}</p>
    <hr></hr>
    <p>{`Okay, okay, yes. That first one is me.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Person #2 - Runs a $20m/yr+ ecommerce businesss`}</strong></p>
    <p>{`Become the best, loyal and kind father, friend and human being.`}</p>
    <p>{`Actual goal is to personally help change 500 people's lives.`}</p>
    <p>{`I want the soundtrack "My Way" of Frank Sinatra to be the soundtrack of my fucking life. (they better play it at my funeral or otherwise I am not coming.)`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Person #3 - Runs a $40m/yr+ ecommerce business`}</strong></p>
    <p>{`Use brute force when starting anything, it won’t be pretty but I’ll learn something.`}</p>
    <p>{`Stay hungry. It’s OK to HATE losing, never f*cking get used to it. ABL (Always Be Learning)`}</p>
    <p>{`Be patient and overly loving with my family. Instill confidence, curiosity and courage in my kids.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Person #4, Owns a $1B+ real estate portfolio`}</strong></p>
    <p>{`Be the best husband, father, son, brother, and friend that I can be. Empower others; to create; and to give back effusively.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Person #5, Venture Capitalist`}</strong></p>
    <p>{`Never look back and think, why didn’t I enjoy that more?`}</p>
    <p>{`Continue making bold decisions, it’s fine to be a misfit caricature of success.`}</p>
    <p>{`Be fierce, don’t be an ass, only work on meaningful things, and share opportunities whenever possible; if I ever feel like this person would, hypothetically, attend my funeral then prioritize them.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Person #6, Owns a $100M+ business portfolio`}</strong></p>
    <p>{`Invert to avoid misery. Figure out all the stuff that historically makes other successful people miserable + my own dislikes, and design my life in a way that I can sidestep as much of it as possible while doing as much/building as large a business as I possibly can.`}</p>
    <p>{`Bit of an experiment: can I achieve a lot of successful while avoiding the downsides.`}</p>
    <p>{`More important than that: can I do that while being a good Dad.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Person #7, Tech entrepreneur with 2 small exits`}</strong></p>
    <p>{`Live an extraordinary life. Be a mix of Leonard Cohen & Warren Buffett.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Person #8, Sold a media business for ~$50M`}</strong></p>
    <p>{`Be here now. Be awake / aware more of the time. Notice the awesome richness of each moment more of the time.`}</p>
    <p>{`Be of greater service to others. Spread humor. Take life less seriously. Share more loving kindness. And make some use of my skill at making money.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Person #9, Tech founder of a $5B+ company`}</strong></p>
    <p>{`Personal - be a good person, be great to my family, friends, coworkers Career - do some big shit with great people, empower entrepreneurs and do things my own way`}</p>
    <hr></hr>
    <p><strong parentName="p">{`Person #10, Serial Entrepreneur + Runs a small VC fund`}</strong></p>
    <p>{`In the last couple years I’ve honed my focus on a few things make me happy: surround myself with the people I care about and love including family and friends (close proximity is key) build long term projects that positively impact its constituents (employees, communities, etc) be playful and curious`}</p>
    <hr></hr>
    <p><strong parentName="p">{`So how do you create your own?`}</strong></p>
    <p><strong parentName="p">{`First`}</strong>{` - do what I did, take a stab and throw out a shitty first draft. Just see what comes to mind.`}</p>
    <p><strong parentName="p">{`Second`}</strong>{` - read other people’s and see which ones resonate. For me - these resonated:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`I liked the idea of just doing the opposite of what makes people miserable (person #6’s answer)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`I like the “do big shit with great people, and do things my own way” (person #9)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`I agree that “surround myself with people I care about - close proximity is key” (person #10)`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`I think something about laughing / remembering this whole life is great (person #8)`}</p>
      </li>
    </ol>
    <p><strong parentName="p">{`Third`}</strong>{` - Try this format as your “training wheels” version if you’re getting writers block:`}</p>
    <p>{`For me life is all about `}<strong parentName="p">{`___`}</strong>{` `}<em parentName="p">{`. I want to spend everyday working on`}</em>{` `}<strong parentName="p">{`___`}</strong>{`. And in my free time, I `}<strong parentName="p">{`___`}</strong>{` with `}<strong parentName="p">{`___`}</strong>{`. `}<strong parentName="p">{`At the end of the day, I want to be remembered as someone who is _`}{`_`}{`_`}{`_`}{`_`}{`_`}</strong>{`.`}</p>
    <p>{`There’s no formula, but this is a good starter template to get you writing.`}</p>
    <p><strong parentName="p">{`Lastly,`}</strong>{` Bring it to life.`}</p>
    <p>{`I do an exercise where I close my eyes and I see a picture of myself having achieved it.`}</p>
    <p>{`For example, for my family vision… I can see in my head myself with 3 little kids. One on each leg, and one hanging on my arm like a little monkey. Leaning over to kiss my wife.`}</p>
    <p>{`For my health, I see myself outside in my backyard in the sun. Stretching and my body is fit & flexible.`}</p>
    <p>{`For work, I see myself teaching in front of a stadium full of students.`}</p>
    <p>{`For play, I imagine myself taking time away from the city, going to a winter cabin for the season. With a pile of books to read, snow to ski on, and smores to eat with family.`}</p>
    <p>{`Visualization always felt a little cheesy and hokey to me.`}</p>
    <p>{`Until I started thinking about it the way I think about mockups in my business. A mockup is the design concept of what the product could look like when it’s done. A good mockup paints a picture so vivid and compelling, it gets everyone to work hard as hell for months to make it happen.`}</p>
    <p>{`That’s what I do. I create mockups of my life in my mind.`}</p>
    <p>{`The brain is actually terrible at differentiating imagination from reality. So the more you imagine it, the more real it feels for you, and the more your day-to-day actions will automatically fall in line with that vision.`}</p>
    <p>{`I don’t think you’ll get it right the first try.`}</p>
    <p>{`But I do think it’s worth trying.`}</p>
    <p>{`So for the 30% of you that will actually do it. Give it a try. And send it to me, I’d love to hear it.`}</p>
    <p>{`-shaan`}</p>
    <p><strong parentName="p">{`P.S. if you like this post, `}<a parentName="strong" {...{
          "href": "https://www.youtube.com/watch?v=SemHh0n19LA"
        }}>{`you’ll love this talk`}</a></strong></p>
    <Links links={[{
      title: LinkLabel.Web,
      links: [{
        url: 'https://www.youtube.com/watch?v=SemHh0n19LA',
        label: "Designing Your Life TEDxStanford Talk"
      }]
    }]} mdxType="Links" />
    <PostFooter prevButton={{
      text: postMap.im_obsessed_with_this_business_model.label,
      slug: slugify(postMap.im_obsessed_with_this_business_model.slug)
    }} mdxType="PostFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      